<template>
  <ResetPasswordSent>
    <div class="reset-password">
      <template v-if="!fetching">
        <template v-if="isActionCodeInvalid">
          <FailureMessage
            :message="
              $t(
                `${$options.path}.errors.${response.error.code}.${isActionCodeInvalid}`
              )
            "
            class="fluid"
          />

          <SendResetPasswordForm
            resend
            class="mt-4"
            @success="$emit('success', $event)"
          />
        </template>

        <template v-else>
          <OddMessage
            :title="
              isNewUser
                ? $t(`${$options.path}.title.is-new-user`)
                : $t(`${$options.path}.title.default`)
            "
            :message="
              isNewUser
                ? $t(`${$options.path}.message.is-new-user`)
                : $t(`${$options.path}.message.default`)
            "
          />

          <ResetPasswordForm
            class="mt-4"
            @success="onSuccess"
            @failure="onFailure"
          />
        </template>
      </template>

      <Progress v-if="fetching" color="secondary" />
    </div>
  </ResetPasswordSent>
</template>

<script>
// http://localhost:8080/reset-password?mode=resetPassword&oobCode=UKU4w0dBR_h3dXM1xi6r64bXKNVucHhFMjFKOARJJZkAAAFz7v_mHA&apiKey=AIzaSyDItv2wCCPg54mfa3ZvuldZGSb9IRBfTyk&continueUrl=https%3A%2F%2Fvision2.odd.co%2Fsign-in&lang=es-419&isNewUser=true

import { mapActions } from 'vuex';
import { getDefaultRoute } from '@/router';
import {
  WithActionCode,
  WithEmail,
  WithIsNewUser,
} from '@/authentication/mixins';

// components
import ResetPasswordSent from './ResetPasswordSent.vue';
// - forms
import ResetPasswordForm from '@/authentication/forms/ResetPasswordForm.vue';
import SendResetPasswordForm from '@/authentication/forms/SendResetPasswordForm.vue';
// - messages
import FailureMessage from '@/components/utils/Message/FailureMessage.vue';
import OddMessage from '@/components/utils/Message/OddMessage.vue';

export default {
  name: 'ResetPassword',
  components: {
    FailureMessage,
    OddMessage,
    ResetPasswordForm,
    ResetPasswordSent,
    SendResetPasswordForm,
  },
  mixins: [WithActionCode, WithEmail, WithIsNewUser],
  data() {
    return {
      isActionCodeExpired: false,

      response: { data: null, error: null },
      fetching: true,
    };
  },
  computed: {
    isActionCodeMalformed() {
      return !_.isString(this.actionCode) || this.actionCode.length === 0;
    },
    isActionCodeInvalid() {
      const { ACTION_CODE_EXPIRED, ACTION_CODE_MALFORMED } = this.$options;

      if (this.isActionCodeMalformed) return ACTION_CODE_MALFORMED;
      if (this.isActionCodeExpired) return ACTION_CODE_EXPIRED;

      return false;
    },
  },
  created() {
    this.verifyActionCode_();
  },
  methods: {
    ...mapActions('authentication', ['verifyResetPasswordCode']),

    async verifyActionCode_() {
      // avoiding an unnecessary Firebase API call
      if (this.isActionCodeMalformed) {
        this.fetching = false;

        return;
      }

      try {
        this.fetching = true;

        const data = await this.verifyResetPasswordCode({
          actionCode: this.actionCode,
        });
        this.response.data = data;

        this.isActionCodeExpired = false;
      } catch (error) {
        this.response.error = error;

        this.isActionCodeExpired = true;
      } finally {
        this.fetching = false;
      }
    },

    async onSuccess(password) {
      if (this.email) {
        try {
          await this.signIn({ email: this.email, password });

          this.$router.push(getDefaultRoute()).catch(() => {});
        } catch (error) {
          this.$router.push({ name: 'sign-in' }).catch(() => {});
        }
      } else this.$router.push({ name: 'sign-in' }).catch(() => {});
    },
    onFailure(error) {
      if (error.code === 'auth/invalid-action-code')
        this.isActionCodeExpired = true;
    },
  },

  path: 'c.reset-password',

  ACTION_CODE_EXPIRED: 'expired',
  ACTION_CODE_MALFORMED: 'malformed',
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
</style>
