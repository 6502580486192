<template>
  <Message
    icon="error"
    icon-color="red"
    message-color="secondary"
    v-bind="$attrs"
  />
</template>

<script>
// components
import Message from './index.vue';

export default {
  name: 'FailureMessage',
  components: { Message },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
</style>
