<template>
  <FormulateForm
    ref="form"
    v-model="payload"
    class="reset-password-form fluid v-spacing-3"
    @input="validate"
    @submit="onSubmit"
  >
    <FormulateInput
      type="password"
      :placeholder="$t(`${$options.path}.password`)"
      name="password"
      autocomplete="new-password"
      validation="required"
      :validation-name="$t(`${$options.path}.password`)"
    />
    <FormulateInput
      type="password"
      :placeholder="$t(`${$options.path}.password-confirm`)"
      name="passwordConfirm"
      autocomplete="new-password"
      validation="bail|required|confirm:password"
      :validation-name="$t(`${$options.path}.password-confirm`)"
    />

    <AsyncMessage
      :fetching="fetching"
      :message="
        response.error && $t(`errors.${response.error.code || 'default'}`)
      "
    />

    <FormulateInput type="submit" :disabled="hasErrors">
      <EButton :disabled="hasErrors" filled lg>
        {{ $t(`${$options.path}.action`) }}
      </EButton>
    </FormulateInput>
  </FormulateForm>
</template>

<script>
import { mapActions } from 'vuex';
import { WithActionCode } from '@/authentication/mixins';

// components
import AsyncMessage from '@/authentication/components/AsyncMessage.vue';

export default {
  name: 'ResetPasswordForm',
  components: { AsyncMessage },
  mixins: [WithActionCode],
  data() {
    return {
      payload: { password: '', passwordConfirm: '' },
      hasErrors: true,

      response: { data: null, error: null },
      fetching: false,
    };
  },
  methods: {
    ...mapActions('authentication', ['resetPassword']),

    async validate() {
      const { form } = this.$refs;
      if (!form) return;

      this.hasErrors = await form.hasValidationErrors();
    },

    async onSubmit() {
      const { payload, actionCode } = this;

      try {
        this.fetching = true;

        const data = await this.resetPassword({
          newPassword: payload.password,
          actionCode,
        });
        this.response.data = data;

        this.$emit('success', payload.password);
      } catch (error) {
        this.response.error = error;

        this.$emit('failure', error);

        throw error;
      } finally {
        this.fetching = false;
      }
    },
  },

  path: 'c.reset-password-form',
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
</style>
