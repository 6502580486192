<template>
  <div class="v-reset-password authentication-view">
    <div class="v-reset-password__body">
      <ResetPassword />
    </div>
  </div>
</template>

<script>
// components
import ResetPassword from '@/authentication/components/ResetPassword.vue';

export default {
  name: 'VResetPassword',
  components: { ResetPassword },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
</style>
